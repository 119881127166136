<template>
  <div class="radio-button" :class="{ 'radio-button-active': active }">
    <div class="text">
      <p class="selector-title sub-title-two">{{ text }}</p>
    </div>
    <div v-if="active" :class="{ extra: hasExtra }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioButton",
  props: {
    text: String,
    description: String,
    active: Boolean,
    hasExtra: Boolean
  }
}
</script>

<style lang="scss" scoped>
.radio-button-active {
  //box-shadow: 0 4px 5px 0 rgba(0,0,0,.06);
  border-color: rgba(79,95,125,.8);
  .text {
    &::before {
      border-color: var(--aurizor-blue) !important;
      background: var(--aurizor-blue) !important;
    }
    &::after {
      background: #FFF !important;
    }
  }
}
.radio-button {
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #dfdfdf;
  align-items: center;

  .extra {
    padding: 8px 16px 16px;
  }

  .text {
    padding: 16px 48px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &::before {
      content: '';
      background: #dfdfdf;
      border: 1px solid #c1c1c1;
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      left: 16px;
      position: absolute;
    }

    &::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background: #dfdfdf;
      border-radius: 20px;
      position: absolute;
      left: 22px;
    }
  }
}
</style>