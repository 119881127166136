<template>
  <div>
    <InfoTable title="Overview">
      <InfoWithOutTable title="WordPress" :content="wpVersion" />
      <InfoWithOutTable title="Storage" :content="availableStorage" />
    </InfoTable>
  </div>
</template>

<script>
import InfoTable from "@/components/Website/InfoTable";
import InfoWithOutTable from "@/components/Website/InfoWithOutTable";

export default {
  name: "WebsiteOverview",
  components: {
    InfoTable,
    InfoWithOutTable
  },
  computed: {
    availableStorage() {
      const availableBytes = (this.packageInfo.storage * 1024 * 1024) - this.packageInfo.usedStorage;
      if (availableBytes === 0) return '0 GB Available';
      return (availableBytes / 1024 / 1024 / 1024).toFixed(1) + ' GB Available';
    }
  },
  props: {
    packageInfo: {
      type: Object,
      required: true
    },
    wpVersion: String,
  }
}
</script>