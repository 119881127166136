<template>
  <div>
    <InfoTable title="Invoices">
      <InfoRow
        :columns="['Transaction ID', 'Due Date', 'Amount', 'Status', 'Actions']"
        :data="invoicesData"
        @payClick="processPayInvoice"
      />
    </InfoTable>
    <Modal v-if="payment.showModal" @close="payment.showModal = false">
      <template slot="header">
        <h6>Pay Invoice</h6>
      </template>
      <div>
        <p style="margin-bottom: 8px">Select payment method</p>
        <RadioButton
          text="Debit/Credit Card"
          :active="payment.method === 'card'"
          @click.native="payment.method = 'card'"
        />
        <RadioButton
          text="bKash"
          :active="payment.method === 'bkash'"
          @click.native="payment.method = 'bkash'"
        />
        <RadioButton
          text="Rocket"
          :active="payment.method === 'rocket'"
          @click.native="payment.method = 'rocket'"
        />
      </div>
      <template slot="footer">
        <Button
          :class="{ 'disable-click': payment.working }"
          classes="primary-btn"
          :name="'Pay ' + payment.amount + ' BDT'"
          :show-done-sign="payment.done"
          :show-loader="payment.working"
          :with-loader="true"
          height="40px"
          padding="0 1.6rem"
          fontSize="16px"
          @click.native="payInvoice"
        ></Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import axios from "@/axios";
import dayjs from "dayjs";

import InfoTable from "@/components/Website/InfoTable";
import InfoRow from "@/components/Website/InfoRow";
import Modal from "@/components/Modal";
import Button from "@/components/Button/Button";
import RadioButton from "@/components/Button/RadioButton";

export default {
  name: "WebsiteInvoice",
  components: {
    RadioButton,
    InfoRow,
    InfoTable,
    Modal,
    Button,
  },
  props: {
    invoices: {
      type: Array,
      required: true,
    },
  },
  computed: {
    invoicesData() {
      const data = [];
      this.invoices.forEach((i) => {
        data.push([
          i.transactionId,
          i.dueDate ? dayjs(i.dueDate).format("DD MMM YYYY") : "-",
          i.amount,
          i.paymentStatus,
          i.paymentStatus !== "paid"
            ? [{ class: "info-edit", name: "Pay", event: "payClick" }]
            : "",
        ]);
      });
      return data;
    },
  },
  data() {
    return {
      payment: {
        showModal: false,
        working: false,
        done: false,
        invoiceId: "",
        amount: "",
        method: "card",
      },
    };
  },
  methods: {
    processPayInvoice(index) {
      const invoice = this.invoices[index];
      this.payment.invoiceId = invoice._id;
      this.payment.amount = invoice.amount;
      this.payment.showModal = true;
    },
    async payInvoice() {
      if (!this.payment.working) {
        this.payment.working = true;
        try {
          const res = await axios.get(
            `${process.env.VUE_APP_API_URL}/payment/pay/${this.payment.invoiceId}`
          );
          if (this.payment.method === "bkash") {
            window.location = res.data.gatewayUrl.bkash;
          } else if (this.payment.method === "rocket") {
            window.location = res.data.gatewayUrl.rocket;
          } else {
            window.location = res.data.gatewayUrl.default;
          }
        } catch (err) {
          this.$toasted.error("Something went wrong");
          this.payment.working = false;
        }
      }
    },
    showInfoFromQuery() {
      if (this.$route.query.message)
        this.$toasted.show(this.$route.query.message);
      if (this.$route.query.error) this.$toasted.error(this.$route.query.error);
    },
  },
  created() {
    this.showInfoFromQuery();
  },
};
</script>
