<template>
  <div class="content">
    <div class="single-info">
      <div class="info-title">
        <p>{{ title }}</p>
      </div>
      <div class="info-content">
        <p>{{ content }}</p>
      </div>
      <div class="info-action">
        <button class="info-danger" v-if="enableDanger" @click="$emit('dangerClick')">{{ enableDanger }}</button>
        <button class="info-edit" v-if="enableEdit" @click="$emit('editClick')">Edit</button>
        <button class="info-restore" v-if="enableRestore" @click="$emit('restoreClick')">Restore</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoWithOutTable",
  props: {
    title: String,
    content: String,
    enableEdit: Boolean,
    enableRestore: Boolean,
    enableDanger: String
  }
}
</script>
