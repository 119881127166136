<template>
  <div>
    <InfoTable title="Backups">
      <template slot="buttons">
        <Button
          :class="{ 'disable-click': create.working }"
          classes="primary-btn"
          name="Create New"
          :show-done-sign="create.done"
          :show-loader="create.working"
          :with-loader="true"
          height="40px"
          padding="0 1.6rem"
          fontSize="16px"
          @click.native="createBackup"
        ></Button>
      </template>
      <InfoRow
        v-if="backupData.length > 0"
        :columns="['Name', 'Date', 'Actions']"
        :data="backupData"
        @restoreClick="processBackupRestore"
        @downloadClickDB="downloadDBBackup"
        @downloadClickSite="downloadSiteBackup"
        @dangerClick="processBackupDelete"
      />
      <p
        v-else
        style="font-size: 16px; color: #676767; padding: 16px 24px 24px;"
      >
        You don't have any backups.
      </p>
    </InfoTable>
    <Modal v-if="restore.showModal" @close="restore.showModal = false">
      <template slot="header">
        <h6>Restore Backup</h6>
      </template>
      <div>
        <p>Are you sure you want to restore this backup?</p>
      </div>
      <template slot="footer">
        <Button
          :class="{ 'disable-click': restore.working }"
          classes="primary-btn"
          name="Restore"
          :show-done-sign="restore.done"
          :show-loader="restore.working"
          :with-loader="true"
          height="40px"
          padding="0 1.6rem"
          fontSize="16px"
          @click.native="restoreBackup"
        ></Button>
      </template>
    </Modal>
    <Modal
      v-if="deleteBackupInfo.showModal"
      @close="deleteBackupInfo.showModal = false"
    >
      <template slot="header">
        <h6>Delete Backup</h6>
      </template>
      <div>
        <p>Are you sure you want to delete this backup?</p>
      </div>
      <template slot="footer">
        <Button
          :class="{ 'disable-click': deleteBackupInfo.working }"
          classes="primary-btn"
          name="Delete"
          :show-done-sign="deleteBackupInfo.done"
          :show-loader="deleteBackupInfo.working"
          :with-loader="true"
          height="40px"
          padding="0 1.6rem"
          fontSize="16px"
          @click.native="deleteBackup"
        ></Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import axios from '@/axios';
import dayjs from 'dayjs';

import InfoTable from '@/components/Website/InfoTable';
import InfoRow from '@/components/Website/InfoRow';
import Modal from '@/components/Modal';

import Button from '@/components/Button/Button';

export default {
  name: 'WebsiteBackup',
  components: {
    InfoRow,
    InfoTable,
    Modal,
    Button,
  },
  props: {
    backups: {
      type: Array,
      required: true,
    },
    siteId: {
      type: String,
      required: true,
    },
  },
  computed: {
    backupData() {
      const data = [];
      this.backups.forEach((b) => {
        data.push([
          b.backupName,
          dayjs(b.createdAt).format('DD MMM YYYY'),
          [
            { class: 'info-danger', name: 'Delete', event: 'dangerClick' },
            { class: 'info-edit', name: 'Restore', event: 'restoreClick' },
            {
              class: 'info-edit',
              name: 'DB',
              event: 'downloadClickDB',
              icon: 'download',
            },
            {
              class: 'info-edit',
              name: 'Site',
              event: 'downloadClickSite',
              icon: 'download',
            },
          ],
        ]);
      });
      return data;
    },
  },
  data() {
    return {
      restore: {
        showModal: false,
        working: false,
        done: false,
        _id: '',
      },
      deleteBackupInfo: {
        showModal: false,
        working: false,
        done: false,
        _id: '',
      },
      create: {
        working: false,
        done: false,
      },
    };
  },
  methods: {
    processBackupDelete(index) {
      const backup = this.backups[index];
      this.deleteBackupInfo._id = backup._id;
      this.deleteBackupInfo.showModal = true;
    },
    processBackupRestore(index) {
      const backup = this.backups[index];
      this.restore._id = backup._id;
      this.restore.showModal = true;
    },
    async restoreBackup() {
      this.$emit('fetchBackups');
      if (!this.restore.working) {
        this.restore.working = true;
        try {
          await axios.get(
            `${process.env.VUE_APP_API_URL}/site/${this.siteId}/restore/${this.restore._id}`
          );

          this.$emit('fetchBackups');

          this.restore._id = '';
          this.restore.done = true;

          setTimeout(() => {
            this.restore.done = false;
            this.restore.showModal = false;
          }, 1000);
        } catch (err) {
          this.$toasted.error('Something went wrong');
        }
        this.restore.working = false;
      }
    },
    async deleteBackup() {
      if (!this.deleteBackupInfo.working) {
        this.deleteBackupInfo.working = true;
        try {
          await axios.delete(
            `${process.env.VUE_APP_API_URL}/site/${this.siteId}/backup/${this.deleteBackupInfo._id}`
          );

          this.$emit('fetchBackups');

          this.deleteBackupInfo._id = '';
          this.deleteBackupInfo.done = true;

          setTimeout(() => {
            this.deleteBackupInfo.done = false;
            this.deleteBackupInfo.showModal = false;
          }, 1000);
        } catch (err) {
          this.$toasted.error('Something went wrong');
        }
        this.deleteBackupInfo.working = false;
      }
    },
    async createBackup() {
      if (!this.create.working) {
        this.create.working = true;
        try {
          await axios.post(
            `${process.env.VUE_APP_API_URL}/site/${this.siteId}/backup`
          );

          this.$emit('fetchBackups');
          this.create.done = true;

          setTimeout(() => {
            this.create.done = false;
          }, 1000);
        } catch (err) {
          this.$toasted.error('Something went wrong');
        }
        this.create.working = false;
      }
    },
    async downloadDBBackup(index) {
      const backup = this.backups[index];
      window.open(
        `${process.env.VUE_APP_API_URL}/site/${this.siteId}/backup/download?backupId=${backup._id}&type=db`
      );
    },
    async downloadSiteBackup(index) {
      const backup = this.backups[index];
      window.open(
        `${process.env.VUE_APP_API_URL}/site/${this.siteId}/backup/download?backupId=${backup._id}&type=wp`
      );
    },
  },
};
</script>

<style scoped></style>
