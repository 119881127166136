<template>
  <div class="info-table">
    <div class="title">
      <h6 style="margin-right: 16px">{{ title }}</h6>
      <slot name="buttons"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "InfoTable",
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.info-table {
  border: 1px solid var(--border);
  border-radius: 4px;
  margin-bottom: 32px;

  .title {
    height: 66px;
    display: flex;
    align-items: center;
    padding: 0 24px;
  }

  .content {
    .single-info {
      height: 51px;
      border-bottom: 1px solid var(--border);
      display: flex;
      align-items: center;
      padding: 24px;

      p {
        font-size: 1.6rem;
      }

      &:last-child {
        border-bottom: 0;
      }

      &:first-child {
        border-top: 1px solid var(--border);
      }

      .info-title {
        min-width: 140px;
      }

      .info-content {
        width: 365px;
      }

    }
    .info-action {
      min-width: 143px;
      display: flex;
      justify-content: flex-end;
      button {
        margin-left: 24px;
        &:first-child {
          margin-left: 0;
        }
      }
      .info-danger {
        color: var(--red-btn-text);
        font-size: 16px;
        background: none;

        &:hover {
          text-decoration: underline;
        }
      }
      .info-edit, .info-restore {
        color: var(--aurizor-blue);
        font-size: 16px;
        background: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>