<template>
  <div>
    <InfoTable title="Website">
      <InfoWithOutTable
        title="Domain"
        :content="websiteDomain"
        enableEdit
        @editClick="editDomain.showModal = true"
      />
      <InfoWithOutTable
        v-if="!website.sslCertificateIsActive && website.customDomainIsActive"
        title="SSL"
        :content="websiteSSL"
        enableEdit
        @editClick="editSSL.showModal = true"
      />
      <InfoWithOutTable
        v-if="!website.sslCertificateIsActive && !website.customDomainIsActive"
        title="SSL"
        content="Active"
      />
      <InfoWithOutTable
        v-if="website.sslCertificateIsActive"
        title="SSL"
        :content="websiteSSL"
        enableDanger="Remove SSL"
        @dangerClick="removeSSL.showModal = true"
      />
    </InfoTable>

    <!-- Domain Edit Modal -->
    <modal
      v-if="editDomain.showModal"
      @close="editDomain.showModal = false"
      width="650"
    >
      <template slot="header">
        <h6>Edit Domain</h6>
      </template>
      <div>
        <div>
          <radio-button
            text="Use Default Domain"
            description="Description"
            :active="!editDomain.customDomainUsage"
            @click.native="editDomain.customDomainUsage = false"
          />
          <radio-button
            text="Use Custom Domain"
            description="Description"
            :active="editDomain.customDomainUsage"
            :hasExtra="true"
            @click.native="editDomain.customDomainUsage = true"
          >
            <div class="input">
              <label for="custom-domain">Custom Domain</label>
              <input
                type="text"
                id="custom-domain"
                v-model="editDomain.customDomain"
                autocomplete="off"
                placeholder="example.com"
              />
              <p v-if="$v.editDomain.customDomain.$error" class="input-error">
                Please enter a valid domain name without http or https.
              </p>
            </div>
            <div class="dns-instruction" v-if="editDomain.customDomain">
              <p class="sub-title" style="font-size: 16px;">
                Set the following record on your DNS provider.
              </p>
              <div class="record">
                <table>
                  <tr>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Value</th>
                  </tr>
                  <tr>
                    <td>CNAME</td>
                    <td>{{ editDomain.customDomain }}</td>
                    <td>alias.aurizor.com</td>
                  </tr>
                </table>
              </div>
            </div>
          </radio-button>
        </div>
      </div>
      <template slot="footer">
        <Button
          :class="{ 'disable-click': editDomain.working }"
          classes="primary-btn"
          name="Save"
          :show-done-sign="editDomain.done"
          :show-loader="editDomain.working"
          :with-loader="true"
          height="40px"
          padding="0 1.6rem"
          fontSize="16px"
          @click.native="updateDomain"
        ></Button>
      </template>
    </modal>

    <!-- SSL Edit Modal -->
    <modal v-show="editSSL.showModal" @close="editSSL.showModal = false">
      <template slot="header">
        <h6>Add SSL Certificate</h6>
      </template>
      <div>
        <div class="ssl-errors" v-if="editSSL.errors.length > 0">
          <p v-for="(error, index) in editSSL.errors" :key="index">
            {{ error }}
          </p>
        </div>
        <div>
          <div class="input">
            <label for="ssl-cert">SSL Certificate</label>
            <textarea
              id="ssl-cert"
              style="height: 200px"
              v-model="editSSL.sslCert"
            ></textarea>
          </div>
          <div class="input">
            <label for="private-key">Private Key</label>
            <textarea
              id="private-key"
              style="height: 200px"
              v-model="editSSL.privateKey"
            ></textarea>
          </div>
          <div class="input">
            <label for="ca-bundle">CA Bundle (optional)</label>
            <textarea
              id="ca-bundle"
              style="height: 200px"
              v-model="editSSL.caBundle"
            ></textarea>
          </div>
        </div>
      </div>
      <template slot="footer">
        <Button
          :class="{ 'disable-click': editSSL.working }"
          classes="primary-btn"
          name="Add SSL Certificate"
          :show-done-sign="editSSL.done"
          :show-loader="editSSL.working"
          :with-loader="true"
          height="40px"
          padding="0 1.6rem"
          fontSize="16px"
          @click.native="addSSL"
        ></Button>
      </template>
    </modal>

    <!-- Remove SSL Modal -->
    <Modal v-if="removeSSL.showModal" @close="removeSSL.showModal = false">
      <template slot="header">
        <h6>Remove SSL</h6>
      </template>
      <div>
        <p>Are you sure you want to remove SSL?</p>
      </div>
      <template slot="footer">
        <Button
          :class="{ 'disable-click': removeSSL.working }"
          classes="primary-btn"
          name="Remove"
          :show-done-sign="removeSSL.done"
          :show-loader="removeSSL.working"
          :with-loader="true"
          height="40px"
          padding="0 1.6rem"
          fontSize="16px"
          @click.native="removeSSLCertificate"
        ></Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import axios from "@/axios";
import { required } from "vuelidate/lib/validators";

import InfoTable from "@/components/Website/InfoTable";
import InfoWithOutTable from "@/components/Website/InfoWithOutTable";
import Modal from "@/components/Modal";
import RadioButton from "@/components/Button/RadioButton";
import Button from "@/components/Button/Button";

const domainValidator = (value) => {
  if (value) {
    return /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/.test(
      value
    );
  }
  return false;
};

export default {
  name: "WebsiteInfo",
  components: {
    InfoTable,
    InfoWithOutTable,
    Modal,
    RadioButton,
    Button,
  },
  props: {
    website: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editDomain: {
        working: false,
        done: false,
        showModal: false,
        customDomainUsage: this.website.customDomainIsActive,
        customDomain: this.website.customDomain,
      },
      editSSL: {
        working: false,
        done: false,
        showModal: false,
        sslCert: "",
        privateKey: "",
        caBundle: "",
        errors: [],
      },
      removeSSL: {
        working: false,
        done: false,
        showModal: false,
      },
    };
  },
  computed: {
    websiteDomain() {
      if (this.website.customDomainIsActive) return this.website.customDomain;
      return this.website.websiteUrl;
    },
    websiteSSL() {
      if (
        this.website.customDomainIsActive &&
        this.website.sslCertificateIsActive
      )
        return "Custom SSL";
      if (this.website.customDomainIsActive) return "Not Active";
      return "Active";
    },
  },
  methods: {
    async addSSL() {
      const sslModal = document.querySelector(".modal");
      this.editSSL.errors = [];
      if (!this.editSSL.working) {
        this.editSSL.working = true;

        // Check input field
        this.$v.editSSL.$touch();
        let fieldError = false;
        if (this.$v.editSSL.sslCert.$error) {
          this.editSSL.errors.push("SSL Certificate is required");
          fieldError = true;
        }
        if (this.$v.editSSL.privateKey.$error) {
          this.editSSL.errors.push("Private Key is required");
          fieldError = true;
        }
        if (fieldError) {
          this.editSSL.working = false;
          sslModal.scrollTop = 0;
          return false;
        }

        try {
          await axios.put(
            `${process.env.VUE_APP_API_URL}/site/${this.website._id}/ssl`,
            {
              privateKey: this.editSSL.privateKey,
              certificate: this.editSSL.sslCert,
              certBundle: this.editSSL.caBundle,
            }
          );

          this.website.sslCertificateIsActive = true;

          this.editSSL.working = false;
          this.editSSL.done = true;

          setTimeout(() => {
            this.editSSL.done = false;
            this.editSSL.showModal = false;
          }, 1000);

          this.editSSL.privateKey = "";
          this.editSSL.certificate = "";
          this.editSSL.caBundle = "";
        } catch (err) {
          this.$toasted.error("Something went wrong");
        }
        this.editSSL.working = false;
      }
    },
    async removeSSLCertificate() {
      if (!this.removeSSL.working) {
        this.removeSSL.working = true;
        try {
          await axios.delete(
            `${process.env.VUE_APP_API_URL}/site/${this.website._id}/ssl`
          );

          this.website.sslCertificateIsActive = false;

          this.removeSSL.working = false;
          this.removeSSL.done = true;

          setTimeout(() => {
            this.removeSSL.done = false;
            this.removeSSL.showModal = false;
          }, 1000);
        } catch (err) {
          this.$toasted.error("Something went wrong");
        }
        this.removeSSL.working = false;
      }
    },
    async updateDomain() {
      if (!this.editDomain.working) {
        this.editDomain.working = true;
        // Update custom domain
        if (this.editDomain.customDomainUsage) {
          this.$v.editDomain.$touch();
          if (this.$v.editDomain.$error) {
            this.editDomain.working = false;
            return false;
          }

          try {
            await axios.put(
              `${process.env.VUE_APP_API_URL}/site/${this.website._id}/custom-domain`,
              {
                newDomain: this.editDomain.customDomain,
              }
            );

            this.website.customDomain = this.editDomain.customDomain;
            this.website.customDomainIsActive = true;

            this.editDomain.working = false;
            this.editDomain.done = true;

            setTimeout(() => {
              this.editDomain.done = false;
              this.editDomain.showModal = false;
            }, 1000);
          } catch (err) {
            if (err.response.data.error.message === "DOMAIN_EXISTS") {
              this.$toasted.error("This domain is already in use");
            } else {
              this.$toasted.error("Something went wrong");
            }
          }
        } else if (
          !this.editDomain.customDomainUsage &&
          this.website.customDomainIsActive
        ) {
          // Remove custom domain
          try {
            await axios.put(
              `${process.env.VUE_APP_API_URL}/site/${this.website._id}/custom-domain-usage`,
              {
                customDomainIsActive: false,
              }
            );

            this.website.customDomain = "";
            this.editDomain.customDomain = "";
            this.website.customDomainIsActive = false;
            this.website.sslCertificateIsActive = false;

            this.editDomain.working = false;
            this.editDomain.done = true;

            setTimeout(() => {
              this.editDomain.done = false;
              this.editDomain.showModal = false;
            }, 1000);
          } catch (err) {
            this.$toasted.error("Something went wrong");
          }
        } else {
          // Nothing changes
          this.editDomain.showModal = false;
        }
        this.editDomain.working = false;
      }
    },
  },
  validations: {
    editDomain: {
      customDomain: { required, domainValidator },
    },
    editSSL: {
      sslCert: { required },
      privateKey: { required },
    },
  },
};
</script>

<style lang="scss" scoped>
.ssl-errors {
  background: var(--red-btn-bg);
  padding: 10px 16px;
  border-radius: 4px;
  margin-bottom: 16px;

  p {
    font-size: 14px;
    color: var(--red-btn-text) !important;
  }
}
.dns-instruction {
  margin-top: 24px;
  .record {
    background: rgb(0 0 0 / 4%);
    padding: 12px;
    border-radius: 4px;
    margin-top: 8px;
    table {
      font-size: 15px;
      th {
        padding-bottom: 5px;
        text-align: left;
        &:nth-child(2) {
          min-width: 80px;
        }
      }
      td {
        padding-right: 24px;
        font-family: "IBM Plex Mono", monospace;
      }
    }
  }
}
</style>
