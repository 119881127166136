<template>
  <div class="content">
    <table>
      <tr>
        <th v-for="(column, index) in columns" :key="index">
          <span v-if="column.toLowerCase() !== 'actions'">{{ column }}</span>
        </th>
      </tr>
      <tr v-for="(dataArray, dIndex) in data" :key="dIndex">
        <td v-for="(d, index) in dataArray" :key="index">
          <div class="info-action" v-if="typeof d === 'object'">
            <button v-for="(button, index) in d" :key="index"
                    :class="button.class" @click="$emit(button.event, dIndex)">
              <div class="button-with-icon" v-if="button.icon">
                <img v-if="button.icon ==='download'" src="../../assets/img/icon/download.svg" alt="Download Icon">
                {{ button.name }}
              </div>
              <span v-else>{{ button.name }}</span>
            </button>
          </div>
          <p v-else>{{ d }}</p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "InfoRow",
  props: {
    title: String,
    content: String,
    enableEdit: Boolean,
    enableRestore: Boolean,
    enableDanger: String,
    columns: Array,
    data: Array,
  },
}
</script>

<style lang="scss" scoped>
table {
  border-top: 1px solid var(--border);
  p {
    font-size: 1.6rem;
  }

  th {
    text-align: left;
    font-size: 1.6rem;
    font-weight: 600;
    height: 51px;
    text-transform: capitalize;
    &:first-child {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;
    }
  }

  tr {
    &:first-child {
      box-shadow: 0 2px 2px #00000012;
    }
  }

  td {
    text-transform: capitalize;
    height: 51px;
    &:first-child {
      min-width: 210px;
      padding-left: 24px;
    }
    &:nth-child(2) {
      width: 60%;
    }
    &:nth-child(3) {
      width: 40%;
    }
    &:last-child {
      min-width: 145px;
      padding-right: 24px;
    }
    .button-with-icon {
      display: flex;
      img {
        fill: var(--aurizor-blue);
        margin-right: 2px;
        display: block;
        height: 20px;
        width: auto;
      }
    }
   }
}
</style>