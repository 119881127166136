<template>
  <div class="site">
    <FullScreenLoader v-if="showLoader" />
    <div class="basic-info container-small">
      <div class="site-info">
        <div class="site-name">
          <h5>{{ website.siteName }}</h5>
          <p class="status" :style="siteStatusStyle">Active</p>
        </div>
        <div class="site-url">
          <a :href="siteUrl" target="_blank">{{ siteUrl }}</a>
        </div>
      </div>
      <div class="site-basic-action">
        <Button
          class="clear-cache"
          :class="{ 'disable-click': website.clearCacheBtnConf.working }"
          classes="btn-danger"
          name="Clear Cache"
          :show-done-sign="website.clearCacheBtnConf.done"
          :show-loader="website.clearCacheBtnConf.working"
          :with-loader="true"
          height="40px"
          padding="0 1.6rem"
          fontSize="16px"
          @click.native="clearCache"
        />
        <Button
          class="wp-admin"
          style="margin-left: 16px"
          :class="{ 'disable-click': website.wpAdminBtnConf.working }"
          classes="primary-btn"
          name="WP Admin"
          :show-done-sign="website.wpAdminBtnConf.done"
          :show-loader="website.wpAdminBtnConf.working"
          :with-loader="true"
          height="40px"
          padding="0 1.6rem"
          fontSize="16px"
          @click.native="wpAdminLogin"
        />
      </div>
    </div>
    <div class="info-section container-small">
      <div class="nav">
        <router-link
          :to="'/site/' + $route.params.siteId"
          class="nav-element"
          :class="{ active: !$route.params.action }"
        >
          Website
        </router-link>
        <router-link
          :to="'/site/' + $route.params.siteId + '/backups'"
          class="nav-element"
          :class="{ active: $route.params.action === 'backups' }"
        >
          Backups
        </router-link>
        <router-link
          :to="'/site/' + $route.params.siteId + '/invoices'"
          class="nav-element"
          :class="{ active: $route.params.action === 'invoices' }"
        >
          Invoices
        </router-link>
        <router-link
          :to="'/site/' + $route.params.siteId + '/destroy'"
          class="nav-element"
          :class="{ active: $route.params.action === 'destroy' }"
        >
          Destroy
        </router-link>
      </div>
      <div class="info">
        <div class="default" v-if="!$route.params.action">
          <website-overview
            :packageInfo="website.package"
            :wpVersion="website.wpVersion"
          />
          <website-info
            :website="website"
            @updateDomain="updateDomain"
          ></website-info>
        </div>
        <div class="backups" v-if="$route.params.action === 'backups'">
          <WebsiteBackup
            :backups="backups"
            :siteId="website._id"
            @fetchBackups="fetchBackups"
          />
        </div>
        <div class="invoices" v-if="$route.params.action === 'invoices'">
          <WebsiteInvoice :invoices="invoices" :siteId="website._id" />
        </div>
        <div v-if="$route.params.action === 'destroy'">
          <WebsiteDestroy :siteId="website._id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

// Components
import Button from "@/components/Button/Button";
import FullScreenLoader from "@/components/FullScreenLoader";
import WebsiteOverview from "@/components/Website/WebsiteOverview";
import WebsiteInfo from "@/components/Website/WebsiteInfo";
import WebsiteBackup from "@/components/Website/WebsiteBackup";
import WebsiteInvoice from "@/components/Website/WebsiteInvoice";
import WebsiteDestroy from "@/components/Website/WebsiteDestroy";
import { mapGetters } from "vuex";

export default {
  name: "Site",
  components: {
    Button,
    FullScreenLoader,
    WebsiteOverview,
    WebsiteInfo,
    WebsiteBackup,
    WebsiteInvoice,
    WebsiteDestroy,
  },
  data() {
    return {
      website: {},
      backups: [],
      invoices: [],
      showLoader: true,
    };
  },
  computed: {
    siteStatusStyle() {
      if (this.website.status === "active")
        return "border: 1px solid #03CD3B; border-radius: 100px; color: #03CD3B";
      return "border: 1px solid #ccc; border-radius: 100px; color: #ccc";
    },
    siteUrl() {
      const site = this.website;
      if (site.customDomainIsActive) {
        let proto = "http";
        if (site.sslCertificateIsActive) proto = "https";
        return `${proto}://${site.customDomain}`;
      }
      return `https://${site.websiteUrl}`;
    },
    ...mapGetters({ user: "GET_USER" }),
  },
  methods: {
    async getWebsiteInfo() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/site/${this.$route.params.siteId}`
        );
        const website = res.data.website;
        // const website = {
        //   "_id": "5f6be1c19c9e703fe0116c74",
        //   siteName: "My Website",
        //   "customDomainIsActive": true,
        //   "sslCertificateIsActive": true,
        //   "status": "active",
        //   "websiteUrl": "ab7d3c2-mango.tuntuni.co",
        //   "customDomain": "aurizor.com",
        //   "package": {
        //     "_id": "5f81a4aea0b5cca3f1bc9c7d",
        //     "name": "Starter",
        //     "storage": 10240,
        //     "bw": 0,
        //     "expiresAt": "2020-11-11 01:28:19",
        //     "usedStorage": 48869948
        //   },
        //   "createdAt": "2020-10-12 01:28:19"
        // }
        website.wpAdminBtnConf = {
          working: false,
          done: false,
        };
        website.clearCacheBtnConf = {
          working: false,
          done: false,
        };
        this.website = website;
      } catch (err) {
        this.$toasted.error("Something went wrong");
      }
    },
    async wpAdminLogin() {
      const site = this.website;
      site.wpAdminBtnConf.working = true;
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/wp/${site._id}/generate-token`
        );
        const token = res.data.token;
        window.open(
          `${this.siteUrl}/wp-json/aurizor/auth?aurizor_magic_key=${token}&site_id=${site._id}`
        );
        site.wpAdminBtnConf.working = false;
        site.wpAdminBtnConf.done = true;
        setTimeout(() => {
          site.wpAdminBtnConf.done = false;
        }, 1000);
      } catch (err) {
        this.$toasted.error("Something went wrong");
      }
      site.wpAdminBtnConf.working = false;
    },
    async clearCache() {
      const site = this.website;
      site.clearCacheBtnConf.working = true;
      try {
        await axios.get(
          `${process.env.VUE_APP_API_URL}/wp/${site._id}/clear-cache`
        );
        site.clearCacheBtnConf.working = false;
        site.clearCacheBtnConf.done = true;
        setTimeout(() => {
          site.clearCacheBtnConf.done = false;
        }, 1000);
      } catch (err) {
        this.$toasted.error("Something went wrong");
      }
      site.clearCacheBtnConf.working = false;
    },
    async fetchBackups() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/site/${this.$route.params.siteId}/backup`
        );
        this.backups = res.data.backups;
        // this.backups = [{"_id":"5f94934789fb92e5eb757878","backupName":"Backup","createdAt":"2020-10-25 02:49:12","website":{"_id":"5f83ae4c2147e071f3acef43"}},{"_id":"5f94934f89fb92e5eb757879","backupName":"Backup","createdAt":"2020-10-25 02:49:19","website":{"_id":"5f83ae4c2147e071f3acef43"}},{"_id":"5f94935b89fb92e5eb75787a","backupName":"Auto Backup","createdAt":"2020-10-25 02:49:31","website":{"_id":"5f83ae4c2147e071f3acef43"}}]
      } catch (err) {
        this.$toasted.error("Something went wrong");
      }
    },
    async fetchInvoices() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/payment/invoice/${this.$route.params.siteId}`
        );
        this.invoices = res.data.invoices;
      } catch (err) {
        this.$toasted.error("Something went wrong");
      }
    },
    updateDomain(website) {
      this.website = website;
    },
    initFreshChat() {
      window.fcWidget.init({
        token: "ecc1c766-cbae-4ea0-922c-5cf6161ea37b",
        host: "https://wchat.freshchat.com",
        siteId: "AURIZOR",
        externalId: this.user._id,
        restoreId: this.user.freshChatRestoreId
          ? this.user.freshChatRestoreId
          : null,
        config: {
          headerProperty: {
            hideChatButton: false,
          },
        },
      });
      window.fcWidget.user.setProperties({
        phone: this.user.phoneNumber,
      });
      window.fcWidget.user.get((resp) => {
        const status = resp && resp.status;
        // const data = resp && resp.data
        if (status !== 200) {
          window.fcWidget.user.setProperties({
            firstName: this.user.displayName,
            email: this.user.email,
            phone: this.user.phoneNumber,
            phoneCountryCode: `+880`,
          });
          window.fcWidget.on("user:created", async (resp) => {
            const status = resp && resp.status;
            const data = resp && resp.data;
            if (status === 200) {
              if (data.restoreId) {
                await axios.post(
                  `${process.env.VUE_APP_API_URL}/user/freshchat-restore-id`,
                  {
                    restoreId: data.restoreId,
                  }
                );
              }
            }
          });
        } else {
          window.fcWidget.user.setProperties({
            phone: this.user.phoneNumber,
          });
        }
      });
    },
  },
  async created() {
    await this.getWebsiteInfo();
    await this.fetchBackups();
    await this.fetchInvoices();
    setTimeout(() => {
      this.showLoader = false;
    }, 200);
    this.initFreshChat();
  },
};
</script>

<style lang="scss">
.site {
  margin: 56px auto;
  .basic-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
    .site-info {
      .site-name {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        .status {
          padding: 4px 13px;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
          margin-left: 8px;
        }
      }
      .site-url {
        a {
          font-size: 15px;
          line-height: 18px;
          color: #00091a;
        }
      }
    }
    .site-basic-action {
      display: flex;
    }
  }
  .info-section {
    display: flex;
    justify-content: space-between;
    .nav {
      width: 180px;
      display: flex;
      flex-direction: column;
      .nav-element {
        font-size: 1.6rem;
        height: 38px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        color: var(--black);
        text-decoration: none;
      }
      .active {
        border-left: 2px solid var(--aurizor-blue);
        font-weight: 600;
        background: #f8f8f8;
      }
    }
    .info {
      width: 696px;
    }
  }

  .select-wrapper {
    margin-bottom: 16px;
    border-radius: 4px;
    border: 1px solid #dfdfdf;
  }
}
</style>
