<template>
  <div>
    <InfoTable title="Destroy Website">
      <div class="destroy">
        <p>
          This is irreversible. We will destroy your Website and all associated
          backups. All Website data will be scrubbed and irretrievable.
        </p>
        <button @click="destroy.showModal = true" class="btn btn-danger">
          Destroy Website
        </button>
      </div>
    </InfoTable>

    <!-- Website Destroy Modal -->
    <Modal v-if="destroy.showModal" @close="destroy.showModal = false">
      <template slot="header">
        <h6>Destroy Website</h6>
      </template>
      <div>
        <p>Are you sure you want to destroy this website?</p>
      </div>
      <template slot="footer">
        <Button
          :class="{ 'disable-click': destroy.working }"
          classes="btn-danger"
          name="Destroy"
          :show-done-sign="destroy.done"
          :show-loader="destroy.working"
          :with-loader="true"
          height="40px"
          padding="0 1.6rem"
          fontSize="16px"
          @click.native="destroyWebsite"
        ></Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import InfoTable from "@/components/Website/InfoTable";
import Modal from "@/components/Modal";
import Button from "@/components/Button/Button";
import axios from "@/axios";

export default {
  name: "WebsiteDestroy",
  props: {
    siteId: String,
  },
  components: {
    InfoTable,
    Modal,
    Button,
  },
  data() {
    return {
      destroy: {
        showModal: false,
        working: false,
        done: false,
      },
    };
  },
  methods: {
    async destroyWebsite() {
      if (!this.destroy.working) {
        this.destroy.working = true;
        try {
          await axios.delete(
            `${process.env.VUE_APP_API_URL}/site/${this.siteId}`
          );
          await this.$router.push("/?message=Website has been destroyed");
        } catch (err) {
          this.$toasted.error("Something went wrong");
        }
        this.destroy.working = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.destroy {
  padding: 0 24px 24px;

  p {
    font-size: 16px;
    color: #676767;
  }

  button {
    height: 40px;
    padding: 0 1.6rem;
    font-size: 16px;
    margin-top: 8px;
  }
}
</style>
